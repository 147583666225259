.logo {
  float: left;
  width: 120px;
}

.signout {
  float: right;
  margin: 16px 24px 16px 0;
}
.menu-title {
  font-size: 10px;
  /* color: rgba(0, 0, 0, 0.34); */
  color: white;
  font-family:'Sukhumvit Set';
  position: absolute;
  top: 27px;

}
.ps-sidebar-container {
  background-color: transparent !important;
}
.fontSukhumvit {
  font-family:'Sukhumvit Set';
}

.logo {
  padding-left: 7px;
  padding-top: 23px;
  margin-bottom: 20px;
}

.logo > img {
  width: 48px;
  height: auto;
}

.desktop {
  display: inherit;
}
.mobile {
  display: none;
}

@media only screen and (max-width: 750px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: inherit;
  }
}

span.menuTriggerIcon {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 1;
}

div.ps-sidebar-container.ps-sidebar-container{
  overflow-y: unset;
  overflow-x: unset;
}

aside.ps-sidebar-root.ps-sidebar-root{
  min-width: 62px;
  width: 62px;
}

a.ps-menu-button.ps-menu-button{
  display: flex;
  justify-content: center;
}
span.ps-menu-icon.ps-menu-icon{
  margin-right: 0px;
}
a.ps-menu-button.ps-menu-button:hover {
  background-color: transparent;
}

.ant-card-head.ant-card-head{
  background-color: #fafafa;
}